import React, { useEffect } from "react";
import { getImage } from "../../../utils/Calculator";
import { useTranslation } from "react-i18next";
import suitCaseIcon from "../../../images/suitcase.svg";
import passengerIcon from "../../../images/passenger.svg";
import chargerIcon from "../../../images/charger.svg";
import wifiIcon from "../../../images/wifi.svg";
import tvIcon from "../../../images/tv.svg";
import dvdIcon from "../../../images/dvd.svg";

const VehicleTypeCard = ({
  vehicle_type,
  onClick,
  styleSelectedVehicle,
  disabledCard = false,
  isEditBooking = false,
  price,
  handleShowModal = () => { }
}) => {
  const { t, i18n } = useTranslation();

  const getIcon = (iconName) => {
    if (iconName === "Passengers icon")
      return (
        <>
          <img src={passengerIcon} alt="" />
        </>
      );
    else if (iconName === "Suitcases icon")
      return (
        <>
          <img src={suitCaseIcon} alt="" />
        </>
      );
    else if (iconName === "Mobile device charger icon")
      return (
        <>
          <img src={chargerIcon} alt="" />
        </>
      );
    else if (iconName === "television")
      return (
        <>
          <img src={tvIcon} alt="" />
        </>
      );
    else if (iconName === "In-car wifi")
      return (
        <>
          <img src={wifiIcon} alt="" />
        </>
      );
    else if (iconName === "DVD-player icon")
      return (
        <>
          <img src={dvdIcon} alt="" />
        </>
      );
    else return <></>;
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
      <div
        className={
          "card custom-card h-100 shadow-sm-2 gold-border-top-hover " +
          styleSelectedVehicle
        }
      >
        <div className="card-header d-flex justify-content-center ps-3 pt-3 pb-2 ">
          {t("FlatRate")}
        </div>
        <div className="card-body d-flex justify-content-center pt-1 pb-1">
          <h4 className="card-title">
            {t("JPY")}{" "}
            {price.toLocaleString()}{t("JPY.JP")}
          </h4>
        </div>

        <img
          src={getImage(vehicle_type.typename)}
          className="card-img-top px-4 h-100 "
          alt="vehicle"
        />

        <div className="card-header ps-3 pt-3 pb-2">
          {t(vehicle_type.typename.replace(/ /g, ".") + ".Tag")}
        </div>
        <div className="card-body pt-1">
          {isEditBooking ? <h5 className="card-title m-0 p-0">
            {t(vehicle_type.typename.replace(/ /g, "."))}
          </h5> : <h4 className="card-title m-0 p-0">
            {t(vehicle_type.typename.replace(/ /g, "."))}
          </h4>}

        </div>

        <div className="card-body bg-vehicle-card-grey d-flex flex-column">
          {/* <!-- icons --> */}
          <div className="row row-cols-1 gy-2 bg-vehicle-card-grey mb-3">
            {vehicle_type?.optionText1 !== "" ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option1Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    { }
                    {i18n.language === "en"
                      ? vehicle_type?.optionText1.split("/")[0]
                      : vehicle_type?.optionText1.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText2 !== "" ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option2Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText2.split("/")[0]
                      : vehicle_type?.optionText2.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText3 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option3Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText3.split("/")[0]
                      : vehicle_type?.optionText3.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText4 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option4Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText4.split("/")[0]
                      : vehicle_type?.optionText4.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText5 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option5Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText5.split("/")[0]
                      : vehicle_type?.optionText5.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText6 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option6Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText6.split("/")[0]
                      : vehicle_type?.optionText6.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText7 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option7Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText7.split("/")[0]
                      : vehicle_type?.optionText7.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicle_type?.optionText8 !== "" && !isEditBooking ? (
              <div className="col d-flex flex-row">
                <div className="col-1 text-center align-item-center me-2">
                  {getIcon(vehicle_type?.option8Icon)}
                </div>
                <div className="col-auto ps-0">
                  <p className="card-text">
                    {i18n.language === "en"
                      ? vehicle_type?.optionText8.split("/")[0]
                      : vehicle_type?.optionText8.split("/")[1]}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <!-- icons end --> */}

          <div className="row mt-auto ">
            <div className="col-12">
              <div className="d-grid gap-2">
                {/* DESKTOP BUTTON */}
                <button
                  onClick={(e) => {
                    onClick(vehicle_type);
                  }}
                  type="button"
                  className={`btn btn-secondary btn-sm rounded-3 text-dark px-1 d-none d-md-block py-2 icon-hoverable-button ${styleSelectedVehicle}-button`}
                  disabled={disabledCard}
                >
                  {styleSelectedVehicle == "selected-vehicle-card"
                    ? t("Discover.Selected")
                    : isEditBooking ? t("Discover.Select") : t("Discover.BookNow")} <i class="fa fa-arrow-right ms-2"></i>
                </button>
                {/* MOBILE BUTTON */}
                <button
                  onClick={(e) => {
                    handleShowModal();
                    onClick(vehicle_type);
                  }}
                  type="button"
                  className={
                    `btn btn-secondary btn-sm rounded-3 text-dark px-1 d-block d-md-none ` +
                    styleSelectedVehicle +
                    "-button"
                  }
                  disabled={disabledCard}
                >

                  {styleSelectedVehicle == "selected-vehicle-card"
                    ? t("Discover.Selected")
                    : isEditBooking ? t("Discover.Select") : t("Discover.BookNow")} <i class="fa fa-arrow-right ms-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleTypeCard;
