import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkTokenUserAdmin } from "../../utils/authUtils";

const PrivateRoute = ({
  component: Component,
  auth: { loading },
  redirectTo = "/admin",
  ...rest
}) => {

  const {isAuthenticated, isAuthenticatedAdmin} = checkTokenUserAdmin();

  if (rest.path.includes("/admin")) {
    return (
      <Route
        {...rest}
        render={(props) =>
          !loading &&
          (!isAuthenticatedAdmin ? (
            <Redirect to={redirectTo} />
          ) : (
            <Component {...props} />
          ))
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          !loading &&
          (!isAuthenticated ? (
            <Redirect to={redirectTo} />
          ) : (
            <Component {...props} />
          ))
        }
      />
    );
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps)(PrivateRoute);
