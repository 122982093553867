import jwtDecode from "jwt-decode";

export const isTokenValid = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }
  return true;
  // try {
  //   const decodedToken = JSON.parse(atob(token.split('.')[1]));
  //   const currentTime = Date.now() / 1000;
  //   return decodedToken.exp > currentTime;
  // } catch (error) {
  //   return false;
  // }
};

export const checkTokenUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedJwt = jwtDecode(token);
    return (
      decodedJwt.user.role !== "admin" || decodedJwt.user.role === undefined
    );
  }
}

export const checkTokenUserAdmin = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedJwt = jwtDecode(token);
    return {
      isAuthenticated: decodedJwt.user.role !== "admin" || decodedJwt.user.role !== undefined,
      isAuthenticatedAdmin: decodedJwt.user.role === "admin" || decodedJwt.user.role !== undefined
    }
  }

  return {
    isAuthenticated: false,
    isAuthenticatedAdmin: false
  }
}