import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { fetchAccountInfo } from "../../../../../actions/profile";

import visa_icon from "../../../../../icons/visa.svg";
import master_card_icon from "../../../../../icons/master-card.svg";
import jcb_icon from "../../../../../icons/jcb.svg";
import american_ex_icon from "../../../../../icons/american_ex.svg";


import email_icon from "../../../../../icons/email.svg";

import countries from "../../../../../assets/json/countries.json";
import businessData from "../../../../../assets/json/businessData.json";

const BussinessAccountInformation = () => {
  const { t } = useTranslation();
  const [countriesData] = useState(countries);
  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  // section data
  const [email, setEmail] = useState("");
  const [businessInformation, setBusinessInformation] = useState({});
  const [authorizedRepresentativeContact, setAuthorizedRepresentativeContact] =
    useState({});
  const [billingInformation, setBillingInformation] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);

  // business information
  const [countryCodeBusiness, setCountryCodeBusiness] = useState("JP");
  const [prefixPhoneNumberBusiness, setPrefixPhoneNumberBusiness] =
    useState("81");

  const handleLiClickCountryListBusiness = (dialCode, countryCode) => {
    setCountryCodeBusiness(countryCode);
    setPrefixPhoneNumberBusiness(dialCode);
    setSearchPhoneCode(null);
  };

  // authorized representative contact
  const [countryCodeContact, setCountryCodeContact] = useState("JP");
  const [prefixPhoneNumbeContact, setPrefixPhoneNumberContact] = useState("81");

  const handleLiClickCountryListContact = (dialCode, countryCode) => {
    setCountryCodeContact(countryCode);
    setPrefixPhoneNumberContact(dialCode);
    setSearchPhoneCode(null);
  };

  // billing information
  const [countryCodeBilling, setCountryCodeBilling] = useState("JP");
  const [prefixPhoneNumberBilling, setPrefixPhoneNumberBilling] =
    useState("81");

  const handleLiClickCountryListBilling = (dialCode, countryCode) => {
    setCountryCodeBilling(countryCode);
    setPrefixPhoneNumberBilling(dialCode);
    setSearchPhoneCode(null);
  };

  // payment method
  const [newPaymentMethod, setNewPaymentMethod] = useState({});

  const getCodeByDialCode = (dialCodeToSearch) => {
    const foundCountry = countriesData.find(
      (country) => country.dial_code === dialCodeToSearch
    );
    return foundCountry ? foundCountry.code : null; // Return the code or null if not found
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAccountInfo();

      // email
      setEmail(data.email);

      // business information
      setPrefixPhoneNumberBusiness(data.businessDetail.businessInformation.prefix_number);
      setCountryCodeBusiness(data.businessDetail.businessInformation.country_code);

      setBusinessInformation(data.businessDetail.businessInformation);
      setBusinessInformation((prevState) => ({
        ...prevState,
        businessPhoneNumber: data.businessDetail.businessInformation.businessPhoneNumber,
      }));

      // authorized representative contact=
      setPrefixPhoneNumberContact(data.businessDetail.billingInformation.prefix_number);
      setCountryCodeContact(data.businessDetail.billingInformation.country_code);

      setAuthorizedRepresentativeContact(
        data?.businessDetail?.authorizedRepresentativeContact
      );
      setAuthorizedRepresentativeContact((prevState) => ({
        ...prevState,
        phoneNumber: data.businessDetail.authorizedRepresentativeContact.phoneNumber,
      }));

      // billing information
      setPrefixPhoneNumberBilling(data.businessDetail.billingInformation.prefix_number);
      setCountryCodeBilling(data.businessDetail.billingInformation.country_code);

      setBillingInformation(data?.businessDetail?.billingInformation);

      // payment methods
      setPaymentMethods(data.businessDetail.paymentMethod);
    };
    fetchData();
  }, []);

  function getCardType(cardNumber) {
    const cleanedCardNumber = cardNumber.replace(/\D/g, "");

    if (/^4\d{15}$/.test(cleanedCardNumber)) {
      return visa_icon;
    } else if (/^5\d{15}$/.test(cleanedCardNumber)) {
      return master_card_icon;
    } else if (/^3\d{14}$/.test(cleanedCardNumber)) {
      return american_ex_icon;
    } else if (/^35\d{14}$/.test(cleanedCardNumber)) {
      return jcb_icon;
    }

    return "Unknown"; // If none of the patterns match
  }

  function convertToMMYYYY(input) {
    // Check if the input string has a length of 4 and contains only digits
    if (/^\d{4}$/.test(input)) {
      const month = input.slice(0, 2);
      const year = "20" + input.slice(2);

      return `${month}/${year}`;
    } else {
      // Handle invalid input
      return "Invalid input";
    }
  }

  const handleChangeField = (type, name, value) => {
    if (type === "email") {
      setEmail(value);
    } else if (type === "business") {
      setBusinessInformation({ ...businessInformation, [name]: value });
    } else if (type === "contact") {
      setAuthorizedRepresentativeContact({
        ...authorizedRepresentativeContact,
        [name]: value,
      });
    } else if (type === "billing") {
      setBillingInformation({ ...billingInformation, [name]: value });
    } else if (type === "payment_method") {
      setNewPaymentMethod({ ...newPaymentMethod, [name]: value });
    }
  };

  const handleChangeEmail = () => {
    console.log(email);
  };

  const handleChangeBusinss = () => {
    const data = {
      ...businessInformation,
      businessPhoneNumber: businessInformation.businessPhoneNumber,
    };
    console.log(data);
  };

  const handleChangeContact = () => {
    const data = {
      ...authorizedRepresentativeContact,
      phoneNumber:
        prefixPhoneNumbeContact +
        " " +
        authorizedRepresentativeContact.phoneNumber,
    };
    console.log(data);
  };

  const handleChangeBilling = () => {
    const data = {
      ...billingInformation,
      phoneNumber: billingInformation.phoneNumber,
    };
    console.log(data);
  };

  const handleAddNewPaymentMethod = () => {
    console.log(newPaymentMethod);
    setNewPaymentMethod({});
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    window.location = "/";
  };
  console.log(businessData.map(item => item.category));
  return (
    <div>
      <div className="card mt-3 card-myPage">
        <div className="card-body">
          <div className="py-3 px-2">
            <h5 className="mb-3">Email Address</h5>
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form `}
                  id="emailAddress3"
                  placeholder="Email address"
                  name="email"
                  defaultValue={email}
                  onChange={(e) =>
                    handleChangeField("email", "email", e.target.value)
                  }
                />
                <label htmlFor="emailAddress3">
                  <div className="d-flex align-items-center">
                    <img alt="img" src={email_icon} />
                    &nbsp; Email address
                  </div>
                </label>
              </div>
            </div>
            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                onClick={handleChangeEmail}
              >
                Change Email Address
              </button>
            </div>

            <h5 className="mb-3 mt-4">Business Information</h5>
            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating">
                <input
                  type="text"
                  className={`form-control`}
                  id="businessName"
                  placeholder="Business Name"
                  name="businessName"
                  defaultValue={businessInformation.businessName}
                  onChange={(e) =>
                    handleChangeField(
                      "business",
                      "businessName",
                      e.target.value
                    )
                  }
                />
                <label htmlFor="businessName">{t("Business Name")}</label>
              </div>

              <div className="col-md-6">
                <div className="input-group fs-8 ">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      src={`https://flagsapi.com/${countryCodeBusiness}/flat/24.png`}
                    />
                    ({prefixPhoneNumberBusiness})
                  </button>
                  <ul className="dropdown-menu">
                    <div className="px-1 align-items-center mb-3 dropdown-search">
                      <input
                        type="text"
                        className={`form-control p-3 mb-2`}
                        defaultValue={searchPhoneCode}
                        placeholder={t("SelectCountry.Placeholder")}
                        onChange={(e) => setSearchPhoneCode(e.target.value)}
                      />
                    </div>

                    {
                      searchPhoneCode !== null ?

                        countriesData
                          ?.filter((item) => {
                            const searchValue = searchPhoneCode?.toLowerCase() || "";
                            return (
                              item.name.toLowerCase().includes(searchValue) ||
                              item.dial_code.toLowerCase().includes(searchValue)
                            );
                          })
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListBusiness(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          )) :
                        countriesData
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListBusiness(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          ))
                    }
                  </ul>
                  <input
                    type="number"
                    className={`form-control contact-form p-3 `}
                    id="businessPhoneNumber"
                    placeholder={t("Phone.Number")}
                    name="businessPhoneNumber"
                    defaultValue={businessInformation.businessPhoneNumber}
                    onChange={(e) =>
                      handleChangeField(
                        "business",
                        "businessPhoneNumber",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="businessAddress"
                  placeholder="Business Address"
                  name="businessAddress"
                  defaultValue={businessInformation.businessAddress}
                  onChange={(e) =>
                    handleChangeField(
                      "business",
                      "businessAddress",
                      e.target.value
                    )
                  }
                />
                <label htmlFor="businessAddress">{t("Business Address")}</label>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <div className="form-floating">
                  <Select
                    placeholder={t("Business Type")}
                    onChange={(e) =>
                      // console.log(e.value)
                      handleChangeField(
                        "business",
                        "businessType",
                        e.value
                      )
                    }
                    value={{ label: businessInformation.businessIndustry, value: businessInformation.businessIndustry }}
                    options={businessData.map(item => ({ label: item.category, value: item.category }))}
                    styles={{
                      control: (baseStyles, isActive) => ({
                        ...baseStyles,
                        paddingTop: "",
                        paddingLeft: "8px",
                        paddingBotton: "0.75rem",
                        border: "none",
                        borderBottom: "1px solid #DBBE78",
                        borderColor: "#dbbe78",
                        height: "calc(3.5rem + 2px)",
                        borderRadius: "0px",
                        background: "rgba(167, 167, 167, 0.1)",
                        fontSize: "14px",
                        color: "#282828",
                      }),
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 form-floating fs-8">
                
                <select
                  className={`form-select account-info-select`}
                  id="businessType"
                  placeholder="Confirm Password"
                  name="businessType"
                  value={businessInformation.businessType}
                  onChange={(e) =>
                    handleChangeField(
                      "business",
                      "businessType",
                      e.target.value
                    )
                  }
                >
                  {(
                    businessData.find(
                      (item) =>
                        item.category === businessInformation.businessIndustry
                    ) || {}
                  ).types?.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <label htmlFor="businessType">{t("Business Type")}</label>
              </div> */}
              <div className="col-md-6">
                <div className="form-floating">

                  <Select
                    placeholder={t("Business Industries")}
                    onChange={(e) =>
                      handleChangeField(
                        "business",
                        "businessIndustry",
                        e.value
                      )
                    }
                    value={{ label: businessInformation.businessType, value: businessInformation.businessType }}
                    options={businessData.find(category => category.category === businessInformation.businessIndustry)?.types.map(type => ({ label: type, value: type })) || []}
                    styles={{
                      control: (baseStyles, isActive) => ({
                        ...baseStyles,
                        paddingTop: "",
                        paddingLeft: "8px",
                        paddingBotton: "0.75rem",
                        border: "none",
                        borderBottom: "1px solid #DBBE78",
                        borderColor: "#dbbe78",
                        height: "calc(3.5rem + 2px)",
                        borderRadius: "0px",
                        background: "rgba(167, 167, 167, 0.1)",
                        fontSize: "14px",
                        color: "#282828",
                      }),
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 form-floating fs-8">
                <select
                  className={`form-select account-info-select`}
                  id="businessIndustry"
                  name="businessIndustry"
                  value={businessInformation.businessIndustry}
                  onChange={(e) =>
                    handleChangeField(
                      "business",
                      "businessIndustry",
                      e.target.value
                    )
                  }
                >
                  <option>Choose</option>
                  {businessData.map((item, index) => (
                    <option key={index} value={item.category}>
                      {item.category}
                    </option>
                  ))}
                </select>
                <label htmlFor="businessIndustry">
                  {t("Business Industries")}
                </label>
              </div> */}
            </div>

            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating fs-8">
                <input
                  type="number"
                  className={`form-control contact-form`}
                  id="businessRegistrationNumber"
                  placeholder="Business Registratio nNumber"
                  name="businessRegistrationNumber"
                  defaultValue={businessInformation.businessRegistrationNumber}
                  onChange={(e) =>
                    handleChangeField(
                      "business",
                      "businessRegistrationNumber",
                      e.target.value
                    )
                  }
                />
                <label htmlFor="businessRegistrationNumber">
                  {t("Business Registration Number")}
                </label>
              </div>

              <div className="col-md-6 form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="website"
                  placeholder="Website"
                  defaultValue={businessInformation.website}
                  onChange={(e) =>
                    handleChangeField("business", "website", e.target.value)
                  }
                />
                <label htmlFor="website">{t("Website (optional)")}</label>
              </div>
            </div>

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                onClick={handleChangeBusinss}
              >
                Edit Business Information
              </button>
            </div>

            <h5 className="mb-3 mt-4">Authorized Representative Contacts</h5>
            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="fullName"
                  placeholder="Full Name"
                  name="fullName"
                  defaultValue={authorizedRepresentativeContact.fullName}
                  onChange={(e) =>
                    handleChangeField("contact", "fullName", e.target.value)
                  }
                />
                <label htmlFor="fullName">{t("Full Name")}</label>
              </div>

              <div className="col-md-6">
                <div className="input-group fs-8 ">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      src={`https://flagsapi.com/${countryCodeContact}/flat/24.png`}
                    />
                    ({prefixPhoneNumbeContact})
                  </button>
                  <ul className="dropdown-menu">
                    <div className="px-1 align-items-center mb-3 dropdown-search">
                      <input
                        type="text"
                        className={`form-control p-3 mb-2`}
                        defaultValue={searchPhoneCode}
                        placeholder={t("SelectCountry.Placeholder")}
                        onChange={(e) => setSearchPhoneCode(e.target.value)}
                      />
                    </div>

                    {
                      searchPhoneCode !== null ?
                        countriesData
                          ?.filter((item) => {
                            const searchValue = searchPhoneCode?.toLowerCase() || "";
                            return (
                              item.name.toLowerCase().includes(searchValue) ||
                              item.dial_code.toLowerCase().includes(searchValue)
                            );
                          })
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListContact(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          )) :
                        countriesData
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListContact(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          ))
                    }
                  </ul>
                  <input
                    type="number"
                    className={`form-control contact-form p-3 `}
                    id="phoneNumber"
                    placeholder={t("Phone.Number")}
                    name="phoneNumber"
                    defaultValue={authorizedRepresentativeContact.phoneNumber}
                    onChange={(e) =>
                      handleChangeField(
                        "contact",
                        "phoneNumber",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="emailAddress"
                  placeholder="Email Address"
                  name="emailAddress"
                  defaultValue={authorizedRepresentativeContact.emailAddress}
                  onChange={(e) =>
                    handleChangeField("contact", "emailAddress", e.target.value)
                  }
                />
                <label htmlFor="emailAddress2">{t("Email Address")}</label>
              </div>
            </div>

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                onClick={handleChangeContact}
              >
                Edit Contacts
              </button>
            </div>

            <h5 className="mb-3 mt-4">Billing Information</h5>
            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating fs-8">
                <input
                  type="number"
                  className={`form-control contact-form`}
                  id="postCode"
                  placeholder="Post code"
                  name="postcode"
                  defaultValue={billingInformation.postCode}
                  onChange={(e) =>
                    handleChangeField("billing", "postCode", e.target.value)
                  }
                />
                <label htmlFor="postCode">{t("Post.Code")}</label>
              </div>

              <div className="col-md-6 form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="country"
                  placeholder="Country"
                  defaultValue={billingInformation.country}
                  onChange={(e) =>
                    handleChangeField("billing", "country", e.target.value)
                  }
                />
                <label htmlFor="country">{t("Country")}</label>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="contactName"
                  placeholder="Contact Name"
                  name="contactName"
                  defaultValue={billingInformation.contactName}
                  onChange={(e) =>
                    handleChangeField("billing", "contactName", e.target.value)
                  }
                />
                <label htmlFor="contactName">{t("Contact Name")}</label>
              </div>

              <div className="col-md-6">
                <div className="input-group fs-8 ">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      src={`https://flagsapi.com/${countryCodeBilling}/flat/24.png`}
                    />
                    ({prefixPhoneNumberBilling})
                  </button>
                  <ul className="dropdown-menu">
                    <div className="px-1 align-items-center mb-3 dropdown-search">
                      <input
                        type="text"
                        className={`form-control p-3 mb-2`}
                        defaultValue={searchPhoneCode}
                        placeholder={t("SelectCountry.Placeholder")}
                        onChange={(e) => setSearchPhoneCode(e.target.value)}
                      />
                    </div>

                    {
                      searchPhoneCode !== null ?
                        countriesData
                          ?.filter((item) => {
                            const searchValue = searchPhoneCode?.toLowerCase() || "";
                            return (
                              item.name.toLowerCase().includes(searchValue) ||
                              item.dial_code.toLowerCase().includes(searchValue)
                            );
                          })
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListBilling(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          )) :
                        countriesData
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryListBilling(
                                    country.dial_code,
                                    country.code
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          ))
                    }
                  </ul>
                  <input
                    type="number"
                    className={`form-control contact-form p-3 `}
                    id="phoneNumber"
                    placeholder={t("Phone.Number")}
                    name="phoneNumber"
                    defaultValue={billingInformation.phoneNumber}
                    onChange={(e) =>
                      handleChangeField(
                        "billing",
                        "phoneNumber",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="billingAddress"
                  placeholder="Biling Address"
                  name="billingAddress"
                  defaultValue={billingInformation.billingAddress}
                  onChange={(e) =>
                    handleChangeField(
                      "billing",
                      "billingAddress",
                      e.target.value
                    )
                  }
                />
                <label htmlFor="billingAddress">{t("Biling Address")}</label>
              </div>
            </div>

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                onClick={handleChangeBilling}
              >
                Edit Billing Information
              </button>
            </div>

            <h5 className="mb-3 mt-4">Payment Method</h5>
            {paymentMethods.map((item, index) => (
              <div
                className="row bg-light my-3  py-3 px-3 justify-content-between payment-method-custom-border-bottom"
                key={index}
              >
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMethod"
                      // defaultValue={item.method}
                      id={`paymentMethod${index}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`paymentMethod${index}`}
                    >
                      <img
                        src={getCardType(item.cardNumber)}
                        alt=""

                        className="me-2"
                      />
                      <span>
                        {"••••" +
                          item.cardNumber.slice(-4) +
                          ", Exp.: " +
                          convertToMMYYYY(item.mmyy)}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn-close p-2"
                    aria-label="Close"
                  // onClick={() => deletePaymentMethod(index)}
                  ></button>
                </div>
              </div>
            ))}

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                data-bs-toggle="modal"
                data-bs-target="#paymentMethod"
              >
                Add Payment Method
              </button>
            </div>

            <h5 className="mb-3 mt-4">Password</h5>

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                data-bs-toggle="modal"
                data-bs-target="#modalChangePassword"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary mt-4 py-2 rounded"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="paymentMethod"
        tabIndex="-1"
        aria-labelledby="paymentMethodLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="paymentMethodLabel">
                Card Information
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control contact-form`}
                      id="cardNumber"
                      onChange={(e) =>
                        handleChangeField(
                          "payment_method",
                          "cardNumber",
                          e.target.value
                        )
                      }
                    />
                    <label htmlFor="cardNumber">{t("Card Number")}</label>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className={`form-control contact-form`}
                      id="expiryDate"
                      onChange={(e) =>
                        handleChangeField(
                          "payment_method",
                          "expiryDate",
                          e.target.value
                        )
                      }
                    />
                    <label htmlFor="expiryDate">MM/YY</label>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className={`form-control contact-form`}
                      id="cvv"
                      onChange={(e) =>
                        handleChangeField(
                          "payment_method",
                          "cvv",
                          e.target.value
                        )
                      }
                    />
                    <label htmlFor="cvv">CVV</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control contact-form`}
                      id="cardholderName"
                      onChange={(e) =>
                        handleChangeField(
                          "payment_method",
                          "cardholderName",
                          e.target.value
                        )
                      }
                    />
                    <label htmlFor="cardholderName">Cardholder’s Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control contact-form`}
                      id="countryOrRegion"
                      onChange={(e) =>
                        handleChangeField(
                          "payment_method",
                          "countryOrRegion",
                          e.target.value
                        )
                      }
                    />
                    <label htmlFor="countryOrRegion">Country or region</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary rounded"
                data-bs-dismiss="modal"
                onClick={handleAddNewPaymentMethod}
              >
                Add Payment Method
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalChangePassword"
        tabIndex="-1"
        aria-labelledby="modalChangePasswordLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalChangePasswordLabel">
                Warning!
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to change password?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary rounded"
                data-bs-dismiss="modal"
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessAccountInformation;
